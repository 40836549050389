"use client"

import { useState, useEffect, useCallback, useRef, Fragment } from 'react'
import url from 'url'
import { Transition } from '@headlessui/react'
import type { ComponentPropsWithoutRef, Dispatch, SetStateAction } from 'react'
import FocusTrap from 'focus-trap-react'
import type { Options as FocusTrapOptions } from 'focus-trap'
import clsx from 'clsx'
import { usePathname } from 'next/navigation'

// components
import Analytics from '@components/analytics'
import { getIcon } from '@components/icons'
import { Button, Link, Icon } from '@shc/ui'
import Backdrop from '@components/backdrop'
import Heading from '@components/heading'
import type { Menu as IMenu, MenuItemData } from '@lib/types'
import { dataLayerMergePush } from '@lib/services/gtm-service'
import { Montserrat } from 'next/font/google'
import ContactUs from '@components/contact-us'

const montserrat = Montserrat({ subsets: ['latin'], variable: '--font-montserrat' })

const focusTrapOptions: FocusTrapOptions = {
  checkCanFocusTrap: (trapContainers: Element[]): any => {
    const results = trapContainers.map((trapContainer: Element) => {
      return new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (getComputedStyle(trapContainer).visibility !== 'hidden') {
            resolve()
            clearInterval(interval)
          }
        }, 3)
      })
    })
    // Return a promise that resolves when all the trap containers are able to receive focus
    return Promise.all(results)
  },
  fallbackFocus: 'body',
}

const Container = ({ children }: ComponentPropsWithoutRef<'div'>) => (
  <div className="flex flex-col flex-grow w-full pb-8 overflow-x-hidden overflow-y-auto bg-white sm:w-96">
    {children}
  </div>
)

const HR = () => <div className="mx-5 -mt-px border-b border-gray-200" />

interface SectionProps extends ComponentPropsWithoutRef<'div'> {
  level2?: MenuItemData
}

const Section = ({ children, level2, ...props }: SectionProps) => {
  return (
    <div {...props}>
      <HR />
      {children}
    </div>
  )
}

interface LinkPrimaryProps extends MenuItemData, ComponentPropsWithoutRef<any> {
  expandable: boolean
  active?: boolean
}

const LinkPrimary = ({
  icon,
  expandable,
  name,
  route,
  active,
  menuSuperheader,
  isAppInternal,
  isInternal,
  className,

  ...props
}: LinkPrimaryProps) => {
  const Label = (
    <span className="inline-flex items-center text-base font-bold transition-colors pr-50 text-tertiary hover:text-linkhover">
      {icon !== undefined && <Icon icon={getIcon(icon)} className="h-4 pr-1.5" />}
      {name}
    </span>
  )

  const linkClasses =
    'inline-flex py-2 outline-offset-2 focus:outline-2 focus-visible:outline outline-primary w-full rounded items-center place-content-between'

  return (
    <li
      className={clsx(
        'hover:bg-gray-50 px-5 transition-colors flex place-content-between',
        active && 'bg-gray-50',
        className
      )}>
      <div className="w-full">

      {expandable && (
          <button className={linkClasses} {...props}>
            {Label}
            {expandable && <Icon icon="chevron-right" className="h-4 pr-1.5 text-gray-700" />}
          </button>
        )}

        {!expandable && (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={route}
            target={isInternal ? undefined : '_blank'}
            rel={isInternal ? undefined : 'noopener noreferrer'}
            className={linkClasses}
            {...props}
            aria-current={active ? 'page' : undefined}>
            {Label}
          </a>
        )}

      </div>
    </li>
  )
}

interface LinkSecondaryProps extends MenuItemData, Omit<ComponentPropsWithoutRef<'a'>, 'name'> {
  id?: string
  active?: boolean
  classNameLi?: string
}

const LinkSecondary = ({
  name,
  route,
  icon,
  isInternal,
  onClick,
  active,
  classNameLi,
  children,
}: LinkSecondaryProps) => (
  <li
    key={name}
    className={clsx(
      classNameLi,
      'px-5 hover:bg-gray-50 transition-colors',
      active && 'bg-gray-50'
    )}>
    {children}
    <Link
      href={route}
      target={isInternal ? undefined : '_blank'}
      rel={isInternal ? undefined : 'noopener noreferrer'}
      color="dark"
      noUnderline
      aria-current={active ? 'page' : undefined}
      className="block py-2 text-sm !font-semibold outline-offset-2 focus:outline-2 no-underline"
      onClick={onClick}>
      {icon !== undefined && <Icon icon={getIcon(icon)} className="pr-1.5" />}
      {name}
    </Link>
  </li>
)

interface MainLevel2Props {
  idx: number
  level1: any
  level1Items: IMenu
  navSlideIdx: number
  setNavSlideIdx: Dispatch<SetStateAction<number>>
  activePathname: string | null
}

const MainLevel2 = ({
  idx,
  level1,
  level1Items,
  navSlideIdx,
  setNavSlideIdx,
  activePathname = '',
  ...props
}: MainLevel2Props) => {
  // const { track } = useAnalytics()

  return (
    <Transition
      unmount={false} // Do not remove from DOM (for screen readers and performance)
      className="fixed top-0 left-0 bottom-0 bg-white h-full w-[calc(100%_-_4rem)] sm:w-96 origin-left transform z-30 overflow-y-auto overflow-x-hidden"
      show={navSlideIdx === idx}
      enter="!block transition ease-out duration-300" // !block because unmount adds display:none
      enterFrom="-translate-x-96"
      enterTo="translate-x-0"
      leave="transition ease-in duration-200"
      leaveFrom="translate-x-0"
      leaveTo="-translate-x-96"
      {...props}>
      <div className="w-full h-16">
        <div className="px-5">
          <button
            onClick={() => setNavSlideIdx(-1)}
            aria-label="Go back to first level"
            className="flex flex-row items-center py-2 my-3 text-gray-700 focus:outline-primary">
            <Icon icon="chevron-left" className="inline h-4 mr-4" />
            <h5 className="font-semibold">{level1?.name}</h5>
          </button>
        </div>

        <Section noDivider>
          <ul className="pb-8 list-none">
            {level1Items?.map(([level2, level2Items], idx2) => (
              <Section level2={level2} key={`${idx}.${idx2}.items`}>
                <LinkPrimary
                  expandable={false}
                  className={clsx('mt-3', level2Items.length === 0 && 'mb-3')}
                  name={level2.name}
                  route={level2.route}
                  icon={level2.icon}
                  isAppInternal={level2.isAppInternal}
                  isInternal={level2.isInternal}
                />
                {level2Items.length > 0 && (
                  <ul className="list-none mb-3" data-testid={`${idx}.${idx2}.items`}>
                    {level2Items.map(([level3], idx3) => (
                      <LinkSecondary
                        key={`${idx}.${idx2}.${idx3}`}
                        active={level3.route.toLowerCase() === activePathname}
                        {...level3}
                      />
                    ))}
                  </ul>
                )}
              </Section>
            ))}
          </ul>
        </Section>
      </div>
    </Transition>
  )
}

interface MainProps {
  navMenu: IMenu
  navSlideIdx?: number
  setNavSlideIdx: Dispatch<SetStateAction<number>>
  activePathname?: string
}

const Main = ({ navMenu, navSlideIdx = -1, setNavSlideIdx, activePathname = '' }: MainProps) => (
  <>
    <ul className="py-5 list-none">
      {/* element should only appear if the pathname is providers */}
      {activePathname.startsWith('/providers') && (
        <>
          <li className='px-5 hover:bg-gray-50 transition-colors'>
            <Link className='font-sans font-bold transition-colors cursor-pointer outline-2 text-gray-700 outline-black block py-2 text-sm !font-semibold outline-offset-2 focus:outline-2 no-underline' href='/providers'>Clinically Integrated Network</Link>
          </li>
          <div className='mx-5 mb-5 pb-5 border-b border-gray-200'/>
        </>
      )}
      {navMenu &&
        navMenu.map(([level1, level1Items], idx) => {
          const props = {
            icon: level1.icon,
            expandable: level1Items.length > 0,
            name: level1.name,
            route: level1.route,
            isAppInternal: level1.isAppInternal,
            isInternal: level1.isInternal,
          }
          return (
            <Fragment key={idx}>
              <LinkPrimary
                {...props}
                aria-expanded={props.expandable ? navSlideIdx === idx : undefined}
                onClick={(e: React.MouseEvent<any>) => {
                  if (props.expandable) {
                    setNavSlideIdx(idx)
                  } else {
                    dataLayerMergePush(
                      {
                        event: 'menu_drawer_navigation',
                        nav_tree_text: level1.name,
                        nav_tree_level: 1,
                        link_text: level1.name,
                        link_url: e.currentTarget.href,
                        link_domain: level1.isInternal
                          ? window.location.host
                          : url.parse(e.currentTarget.href).host,
                      },
                      level1.dataLayer
                    )
                  }
                }}
              />
              {/* map over items to display expandable menu */}
              {level1Items?.length > 0 && (
                <MainLevel2
                  key={`${idx}.items`}
                  data-testid={`${idx}.items`}
                  idx={idx}
                  level1={level1}
                  level1Items={level1Items}
                  navSlideIdx={navSlideIdx}
                  setNavSlideIdx={setNavSlideIdx}
                  activePathname={activePathname}
                />
              )}
            </Fragment>
          )
        })}
        
        {!activePathname.startsWith('/providers') && (<li className='hover:bg-gray-50 px-5 transition-colors flex place-content-between'>
        <div className='w-full'>
          <Link
            className="font-sans font-bold transition-colors cursor-pointer outline-2 text-tertiary hover:text-linkhover inline-flex py-2 outline-offset-2 focus:outline-2 focus-visible:outline outline-primary w-full rounded items-center place-content-between no-underline"
            href='/prospective-physicians'
          >
            Prospective physicians
          </Link>
        </div>
      </li>)}
    </ul>
    <div className='mx-5 -mt-px border-b border-gray-200'/>
  </>
)

export interface MenuDrawerProps extends ComponentPropsWithoutRef<'nav'> {
  menu: IMenu
  menuSuperheader?: IMenu
  isMenuOpen: boolean
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>
  activePathname: string
  header?: JSX.Element
  showLogin: boolean
  getSession: boolean
}

const MenuDrawer = ({
  menu,
  children,
  isMenuOpen,
  setIsMenuOpen,
  activePathname,
  menuSuperheader,
  header,
  showLogin=true,
  getSession,
  ...props
}: MenuDrawerProps) => {
  const [navSlideIdx, setNavSlideIdx] = useState<number>(-1)
  const containerRef = useRef<HTMLDivElement>(null)
  const closeButtonRef = useRef<HTMLButtonElement>(null)
  const pathName = usePathname()

  const closeDlPush = () =>
    dataLayerMergePush({
      event: 'menu_drawer_navigation_toggle',
      engagement_type: 'Close',
    })

  const close = useCallback(() => {
    setIsMenuOpen(false)
    setNavSlideIdx(-1)
    closeDlPush()
  }, [setIsMenuOpen, setNavSlideIdx])

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (isMenuOpen && event.key === 'Escape') {
        close()
      }
    },
    [isMenuOpen, close]
  )

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const notInContainer =
        containerRef.current && !containerRef.current.contains(event.target as Node)
      const notInCloseButton =
        closeButtonRef.current && !closeButtonRef.current.contains(event.target as Node)
      if (isMenuOpen && notInContainer && notInCloseButton) {
        close()
      }
    },
    [isMenuOpen, containerRef, closeButtonRef, close]
  )

  // Close on esc key
  useEffect(() => {
    document.addEventListener('keydown', escFunction, true)
    return () => {
      document.removeEventListener('keydown', escFunction, true)
    }
  }, [escFunction])

  // Close on click outside menu
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside, containerRef, closeButtonRef])

  // Disabled body scroll when open
  useEffect(() => {
    const hasVScrollbar = window.innerWidth - document.documentElement.clientWidth > 0
    const vScrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    if (isMenuOpen) {
      document.documentElement.style.overflow = 'hidden'
      if (hasVScrollbar) {
        document.documentElement.style.paddingRight = `${vScrollbarWidth}px`
      }
    } else {
      document.documentElement.style.removeProperty('overflow')
      document.documentElement.style.removeProperty('padding-right')
    }
  }, [isMenuOpen])

  return (
    <>
      <FocusTrap active={isMenuOpen} focusTrapOptions={focusTrapOptions}>
        <Transition
          unmount={false} // Do not remove from DOM (for screen readers and performance)
          className="fixed top-0 left-0 z-30 w-full h-full transition-all origin-left scale"
          show={isMenuOpen}
          enter="!block transition ease-out duration-300 delay-100"
          enterFrom="opacity-0 -translate-x-96"
          enterTo="opacity-100 translate-x-0"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 -translate-x-96"
          as="nav"
          aria-labelledby="menu-drawer-button"
          aria-label="Menu Drawer">

          <Button
            aria-label="Menu close"
            color="transparent"
            shape="circle"
            size="sm"
            className="m-3 text-gray-700 transition-all transform !absolute top-0 left-[calc(100%_-_4rem)] sm:left-96 z-30 !outline-transparent"
            onClick={close}
            ref={closeButtonRef}>
            <Analytics
              click={{
                name: 'modal_close',
                data: {
                  navigation_tree: 'Menu Drawer',
                  navigation_subject: 'Menu Drawer',
                  navigation_level: 1,
                  navigation_url: pathName,
                },
              }}
              contexts={[{ name: 'modal', data: { section_name: 'menu_drawer' } }]}
            >
              <Icon icon="xmark-large" className="h-5 text-white" />
            </Analytics>
          </Button>

          <div ref={containerRef} className={clsx('flex flex-col h-full w-[calc(100%_-_4rem)] sm:w-96 font-sans', montserrat.className)}>
            <Container>
              <div className="w-full h-16 flex flex-row items-center py-5 px-5 h-auto" data-testid="menu-drawer-header">
                <Heading
                  level={6}
                  size="h6"
                  className={'text-gray-700'}
                >
                  Menu
                </Heading>
                
                {showLogin ? (
                  <div className="ml-auto">
                    <Analytics
                      click={{
                        name: 'navigation_click',
                        data: {
                          navigation_tree: 'Provider login',
                          navigation_level: 2,
                          navigation_subject: 'Provider login',
                          navigation_url: 'https://providers.scmg.org',
                        },
                      }}
                      contexts={[{ name: 'section', data: { section_name: 'header' } }]}>
                      <Button
                        id="provider-login"
                        aria-label="Provider login"
                        color="primary"
                        shape="rounded"
                        size="sm"
                        width="auto"
                        href="https://providers.scmg.org"
                        as="a"
                        target='_blank'
                      >
                        Provider login
                      </Button>
                    </Analytics>
                  </div>
                ) : ( <div className="ml-auto">
                  <Link
                    href='/app'
                    className="inline-flex flex-row items-center text-sm"
                    noUnderline>
                    <span className='text-tertiary hover:text-linkhover'>My account</span>
                  </Link>
                </div>)} 
              </div>

              <Section data-testid="menu-drawer-main">
              {!!menuSuperheader?.length && (
                  <ul className="list-none py-5">
                    {menuSuperheader?.map(([link], idx) => (
                      <LinkSecondary
                        classNameLi="pl-5 flex items-center pr-0 py-0 md:pr-5"
                        key={idx}
                        name={link.name}
                        route={link.route}
                        className="pl-0">
                        {link.icon && (
                          <Icon
                            icon={getIcon(link.icon)}
                            aria-hidden="false"
                            aria-label={link.name}
                            className="h-3 mr-[6px]"
                          />
                        )}
                      </LinkSecondary>
                    ))}
                  </ul>
                )}
                
                <Analytics
                  click={{
                    name: 'navigation_click',
                    data: {
                      navigation_tree: `menu drawer > ${activePathname}`,
                      navigation_level: 2,
                      navigation_subject: activePathname,
                      navigation_url: activePathname,
                    },
                  }}
                  contexts={[{ name: 'section', data: { section_name: 'header' } }]}>
                  <Main
                    navMenu={menu}
                    navSlideIdx={navSlideIdx}
                    setNavSlideIdx={setNavSlideIdx}
                    activePathname={activePathname}
                  />
                </Analytics>
              </Section>

              <div className="p-5">
               <ContactUs section={"header"}></ContactUs>
              </div>
            </Container>
          </div>
        </Transition>
      </FocusTrap>

      <Transition
        className="fixed inset-0 z-20 block"
        as={Backdrop}
        show={isMenuOpen}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-200 delay-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      />
    </>
  )
}

MenuDrawer.Container = Container
MenuDrawer.Section = Section
MenuDrawer.Main = Main
MenuDrawer.LinkPrimary = LinkPrimary
MenuDrawer.LinkSecondary = LinkSecondary

export default MenuDrawer
