"use client"

import type { ComponentPropsWithoutRef } from 'react'
import type { Menu } from '@lib/types'
import Image from 'next/image'
import Analytics from '@components/analytics'
import { Container, Icon } from '@shc/ui'
// components
import { getIcon } from '@components/icons'
import NavLink from '@components/nav-link'

export interface FooterProps extends ComponentPropsWithoutRef<'footer'> {
  menu: Menu
  socialMenu: Menu
  copyrightDate?: Date
  footerPromo?: boolean
  childrenPosition?: 'start' | 'end'
}

const Footer = ({
  menu,
  socialMenu,
  copyrightDate = new Date(),
  footerPromo = true,
  children,
  childrenPosition = 'start',
  ...props
}: FooterProps) => {
  return (
    <footer {...props}>
      {/* Footer Nav */}
      <div className="bg-gray-50">
        <Container as="nav" className="mx-auto grid grid-cols-12 pt-12 gap-x-5">
          {childrenPosition === 'start' && (
            <div className="col-span-12 sm:col-span-6 xs:col-span-6 lg:col-span-3">{children}</div>
          )}
          {menu.map(([link, children], idx1) => (
            <div key={idx1} className="col-span-12 pt-10 sm:col-span-6 xs:col-span-6 lg:col-span-3 xs:pt-0">
              <h2 className="text-sm font-semibold text-gray-700">{link.name}</h2>
              <ul>
                {children.map(([link2], idx2) => (
                  <li key={idx2} className="mt-2">
                    <Analytics
                      click={{
                        name: 'navigation_click',
                        data: {
                          navigation_tree: `${link.name} > ${link2.name}`,
                          navigation_subject: link2.name,
                          navigation_level: 1,
                          navigation_url: link2.route,
                        },
                      }}
                      contexts={[{ name: 'section', data: { section_name: 'footer' } }]}>
                      <NavLink
                        className="text-sm text-gray-700"
                        href={link2.route}
                        target={link2.isInternal ? undefined : '_blank'}
                        rel={link2.isInternal ? undefined : 'noopener noreferrer'}>
                        {link2.icon !== undefined && (
                          <Icon icon={getIcon(link2.icon)} className="pr-1.5" />
                        )}
                        {link2.name}
                      </NavLink>
                    </Analytics>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {childrenPosition === 'end' && (
            <div className="col-span-12 sm:col-span-6 xs:col-span-6 lg:col-span-3">{children}</div>
          )}
        
          <div className="col-span-12 pt-10 sm:col-span-12 lg:col-span-6 lg:pt-0">
            <div className="flex flex-col gap-y-5 lg:flex-row lg:gap-x-4">
              <div className="min-w-[100px] pt-[6px]">
                <a href="https://www.sharp.com" target="_blank">
                  <Image
                    src="/assets/images/svgs/sharp-logo.svg"
                    width={100}
                    height={32}
                    alt="Sharp logo"
                  />
                </a>
              </div>
              <div className="flex justify-left text-sm">
                  Sharp Community Medical Group is proud to be affiliated with Sharp HealthCare in San Diego County.
              </div>
            </div>
            <div className="flex flex-col gap-y-5 lg:flex-row lg:gap-x-4 pt-5">
              <div className="min-w-[100px] pt-[6px]">
                <a href="https://www.palomarhealth.org" target="_blank">
                  <Image
                    src="/assets/images/palomar-logo.png"
                    width={100}
                    height={32}
                    alt="Palomar logo"
                  />
                </a>
              </div>
              <div className="flex justify-left text-sm">
                Sharp Community Medical Group is proud to be affiliated with Palomar Health in North San Diego County.
              </div>
            </div>
          </div>
        </Container>

        {/* Footer Legal */}
        <Container className="mx-auto grid grid-cols-12 pt-10 pb-6 gap-x-5 gap-y-4">
          <div className="col-span-12 md:col-span-6">
            <p className="text-sm text-left text-gray-700">
              &copy;
              {copyrightDate.getUTCFullYear()} Sharp Community Medical Group
            </p>
          </div>
          <div className="col-span-12 md:col-span-6">
            <p className="text-left md:text-right lg:whitespace-nowrap">
              <Analytics
                click={{
                  name: 'navigation_click',
                  data: {
                    navigation_tree: 'Terms of use',
                    navigation_level: 1,
                    navigation_subject: 'Terms of use',
                    navigation_url: '/terms-of-use',
                  },
                }}
                contexts={[{ name: 'section', data: { section_name: 'footer' } }]}>
                <NavLink href="/terms-of-use" className="text-sm !font-semibold">
                  Terms of use
                </NavLink>
              </Analytics>
            </p>
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer